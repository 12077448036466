import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { titleAnimation } from "../../animation";

// Importing styles and components
import "./aboutpage.styles.scss";
import {
  PrimaryHeading,
  LineHeading,
} from "../../components/headings/headings.components.jsx";
import ArrowDown from "../../images/arrows/arrow-down.svg";
import { useScroll } from "../../components/useScroll.js";
import ScrollTop from "../../components/ScrollTop.js";

// Animation
import { pageAnimation, scrollReveal } from "../../animation.js";

const Aboutpage = () => {
  const [element, controls] = useScroll();
  const [element1, controls1] = useScroll();
  return (
    <motion.div
      className="about-page"
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <div className="header-container">
        <div className="header">
          <motion.span
            style={{ display: "inline-block" }}
            variants={titleAnimation}
          >
            Front-end&nbsp;
            <span className="animated-text-hv-stroke">Developer</span>,
          </motion.span>
          <br />
          <motion.span
            style={{ display: "inline-block" }}
            variants={titleAnimation}
          >
            Web&nbsp;<span className="animated-text-hv-stroke">Designer</span>,
          </motion.span>
          <br />
          <motion.span
            style={{ display: "inline-block" }}
            variants={titleAnimation}
          >
            Freelancer
          </motion.span>
        </div>{" "}
        <p className="intro">
          I’m a web developer specializing in building (and occasionally
          designing) smooth digital experiences. Currently, I’m focused on
          developing my dev-skills and volunteering as web developer lead at{" "}
          <Link
            to={{ pathname: "https://www.aen-nepal.org/" }}
            target="_blank"
            rel="noreferrer"
          >
            aen-nepal
          </Link>
          .
        </p>
      </div>
      <section className="about-page__outlinedIntro">
        <h1>
          &nbsp;&nbsp;I love to <br />
          &nbsp;&nbsp;&nbsp;&nbsp;create minimal <br />
          &nbsp;&nbsp;design and smooth
          <br /> web experience
        </h1>
      </section>
      <motion.section
        className="about-page__services"
        variants={scrollReveal}
        ref={element}
        animate={controls}
        initial="hidden"
      >
        <LineHeading />
        <div className="content">
          <div className="arrow-about">
            <img src={ArrowDown} alt="arrow" />
          </div>
          <div>
            <h3>My Services</h3>
            <p>UI/UX Design</p>
            <p>Branding</p>
            <p>Logo Design</p>
            <p>Front-End</p>
          </div>
        </div>
      </motion.section>
      <motion.section
        className="contacts"
        variants={scrollReveal}
        ref={element1}
        animate={controls1}
        initial="hidden"
      >
        <Link to="/contact" className="hover-link prevent-default-anchor-anim">
          <PrimaryHeading id="four" TextContent="Let's Create &ensp; ➙" />
          <LineHeading />
        </Link>
        <div className="contacts-container">
          <div className="contact-links">
            <div className="social-links">
              <h3>Social</h3>
              <Link
                to={{
                  pathname: "https://twitter.com/developedbysam",
                }}
                target="_blank"
              >
                Twitter
              </Link>
              <Link
                to={{
                  pathname: "https://www.instagram.com/developedbysam",
                }}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </Link>
              <Link
                to={{
                  pathname: "https://www.facebook.com/im.ansarisamir",
                }}
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </Link>
              <Link
                to={{
                  pathname: "https://www.linkedin.com/in/developedbysam",
                }}
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </Link>
            </div>
            <div classname="social-links">
              <h3>Say Hi!</h3>
              <Link
                to={{
                  pathname:
                    "mailto:info@developedbysam.com?Subject=Hello%20User",
                }}
                target="_blank"
                rel="noreferrer"
              >
                info@developedbysam.com
              </Link>
            </div>
          </div>
        </div>
      </motion.section>
      <ScrollTop />
    </motion.div>
  );
};

export default Aboutpage;
