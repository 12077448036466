import React from "react";
import { Link } from "react-router-dom";
import "./blog-utilities.styles.scss";

export const BlogArticleContainer = () => {
  return <div className="blogArticle-container"></div>;
};

export const BlogArticle = () => {
  return <div className="blogArticle"></div>;
};

export const BlogArticleHeading = ({ heading }) => {
  return <h1 className="blogArticle-heading">{heading}</h1>;
};

export const SecondaryTitle = ({ text }) => {
  return <h2 className="secondary-title">{text}</h2>;
};

export const BlogArticleImg = ({ imageUrl, ...otherProps }) => {
  return (
    <div className="blogArticle-img">
      <img src={`${imageUrl}`} {...otherProps} />
    </div>
  );
};

export const BlogArticleText = (props) => {
  console.log(props.innerHtml);
  return <div className="blogArticle-text"></div>;
};

export const BlogArticleParagraph = ({ text }) => {
  console.log(text);
  return <p>{text}</p>;
};

export const OrderedList = ({ listItems }) => {
  return (
    <ol className="ordered-list">
      {listItems.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ol>
  );
};

export const QuestionBox = ({ text }) => {
  return (
    <div className="question-box">
      <p>{text}</p>
    </div>
  );
};

export const CodeBox = ({ text }) => {
  return (
    <div className="code-box">
      <p>{text}</p>
    </div>
  );
};

export const ExternalLink = ({ text, externalLink }) => {
  return (
    <Link
      className="external-link prevent-default-anchor-anim"
      to={{ pathname: { externalLink } }}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </Link>
  );
};

export const VideoResponsive = ({ embedId }) => {
  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
