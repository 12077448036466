import { Link } from "react-router-dom";

export const ArticleData = {
  article1: {
    heading:
      "Getting started with programming—The coding guide I wish I had when I started learning to code.",
    imageUrl: "https://i.ibb.co/xHF8W4B/code-starting-guide.jpg",
    title2: "Here's What I Suggest.",
    title3: "What's Next?",
    para1:
      "Let me tell you one thing. Programming is not easy. Well, nothing’s easy unless you make an effort to make it easy. Note this, “All things are difficult before they are easy,” and it’s true, believe it or not. While anybody can learn how to start coding, many people don’t take the plunge because they are worried they need a computer science degree, they’re too young, their math skills are not good enough blah blah blah…",
    para2:
      "“Coding” in itself is a broad term. There are different paths you can take once you enter the world of programming. Therefore, you don’t have to worry about the skills you have or you need. After all, you will learn a ton along the way. You just need to get started!",
    para3:
      "As you have decided to learn programming from scratch, you have the power to change your life completely – for the better. Understanding programming is the most empowering skill you can teach yourself these days. It’s a huge decision that can lead to incredible future opportunities.",
    para4: [
      <b>
        <i>Congrats!</i>
      </b>,
      " ",
      "For getting here! You’re going to nail it",
    ],
    para5: "Ready to start? Great!",
    para6: "A short story about how I started.",
    para7:
      "When I started coding, it was unorganized. I googled “top websites to learn programming” and randomly picked out a couple of them and jumped right into them. But that was probably not the best way. It was stressful. I was following three courses simultaneously, and keeping up with all three was overwhelming, unmanaged, and unorganized. I didn’t know what I should learn first and then move on to second and third. Soon, I was lost. I joined a dev community on discord, but it ended up the same way. All the technical words people used in the discussions were straight off my brain!",
    para8:
      "Guess what? You don’t have to go through all the hassle because I’m here to help. So, let’s get started.",
    para9:
      "First things first! I cannot stress how important it is to get familiarized with computer architecture, data basics, and the internet. You simply cannot understand big stuff unless you know the basics. Here I am listing some of the essential things you should learn and a couple of free courses to help you understand these topics.",
    para10:
      "That’s it? Well, yeah! More things will come along the way. I don’t want to overwhelm you just by listing tons of steps here, especially when starting from scratch. Just take the baby steps slowly, and you won’t realize how far you’ve come in the end.",
    para11:
      "Hmm! That’s a good question. It is difficult to suggest a particular field and a specific programming language based on my experience. I, personally, started with python because I read it is easier to get started for beginners, and no doubt! It is a beginner-friendly language. I taught myself to build Space Invader, a game clone, with python. Later, I learned that it is not well optimized for building games. It is a programming language highly used in deep learning, data science, and automation. Did I waste time learning python? No, because the concepts I learned using python are helpful across all programming languages (only the syntax is different). Yes, because I used it for a task that is not its best use case, and I had to spend more time learning it for the things it is meant to be used for.",
    para12: [
      "If you know what you are interested in and what field you want to get in, Great! Pick a suitable programming language and start killing it. Otherwise, I would suggest starting with the course",
      " ",
      <Link
        className="external-link prevent-default-anchor-anim"
        to={{
          pathname:
            "https://www.edx.org/course/introduction-computer-science-harvardx-cs50x",
        }}
        target="_blank"
        rel="noreferrer"
      >
        “CS50’s Introduction to Computer Science”.
      </Link>,
      " ",
      "It is a great course (and it’s free on edx) by Harvard University to get started with Computer Science. This course will help you learn about all the things listed above intuitively. I highly recommend starting with this. This course will teach you basic python, HTML, CSS, and JavaScript and give you a vantage point to have a clear path where you want to go from there. Get started with this course now, and thank me later!",
    ],
    para13: [
      "The other course I would suggest is ",
      " ",
      <Link
        className="external-link prevent-default-anchor-anim"
        to={{
          pathname:
            "https://upskillcourses.com/courses/essential-web-developer-course",
        }}
        target="_blank"
        rel="noreferrer"
      >
        “The Essential Web Developer Course”
      </Link>,
      " ",
      "by Upskill. This course will teach a massive set of skills ranging from programming and computer science fundamentals, data structure and more. You’ll learn by building a real-world application, and everything is explained in surprisingly simple language.",
    ],
    para14:
      "If you want to learn and explore on your own, I will suggest you start by using python anyway. It will help you learn all the concepts used in programming because it is easier to understand, and you will not waste over an hour debugging a 50-line code just because you missed a colon, curly braces, or a comma. Start by fundamentals and use it for building small projects it should be used for, such as small automation scripts, data processing, web development, database management, etc. Along the way, you would also want to learn HTML, CSS, and JavaScript once you start to use python for web development.",
    para15: [
      "If I were starting from scratch, I would go with",
      " ",
      <Link
        className="external-link prevent-default-anchor-anim"
        to={{
          pathname:
            "https://www.edx.org/course/introduction-computer-science-harvardx-cs50x",
        }}
        target="_blank"
        rel="noreferrer"
      >
        Harvard’s CS50
      </Link>,
      " ",
      "course.",
    ],
    para16:
      "If you plan to start with the above courses, you will save a lot of time for yourself. Though the courses may seem longer than you thought, I cannot stress how helpful they will be in the long run. You can probably finish the course in a short time if you are consistent enough and you really want to learn to code. Be consistent, and you will soon be a good programmer. By the time you finish the courses or are learning to code on your own, I will be posting a lot of helpful resources to make your path ahead smooth. Make sure to check back for future updates.",
    para17:
      "I am working on the other parts of this beginners’ guide actively. Till then, carry on with the courses above and hang tight!! Keep Going.",
    question1:
      "Okay, I got it. But how do I figure out what I am interested in and what programming language should I pick?",
    question2: "So, what should you do?",
    guideList: [
      "Learn How Computer Works and Data Flow.",
      "Learn How the Internet Works.",
      "Learn How Programming Languages Work.",
      "Figure Out Your Field of Interest and Start Learning with a Suitable Programming Language.",
    ],
  },
  article2: {
    imageUrl: "https://i.ibb.co/KjPkBV0/Installing-Fish-Shell-Thumbnail.jpg",
    ssUrl: [
      "https://i.ibb.co/NL1xTmB/fish-shell-1.png",
      "https://i.ibb.co/ydSCJwW/fish-shell-2.png",
      "https://i.ibb.co/g3s0Qfm/fish-config.png",
    ],
    heading: "How to install fish shell on Ubuntu 20.04.",
    para1:
      "Fish, the friendly interactive shell, is a smart, customizable, interactive, user-friendly, and fully-equipped command line shell built for Linux, macOS, other operating systems. What is great about fish shell is that it supports powerful features like autosuggestions, syntax-highlighting, tab-completion, and more out of the box. Also, configuring fish shell is easier than other alternatives (like bash or zsh) because of its Web-Based Configuration tool.",
    para2:
      "If you want to make your command line more productive, more useful, and more fun without learning a bunch of arcane syntax and configuration options, then fish might be just what you’re looking for! Especially when you just started coding and didn’t want to waste hours configuring your terminal to get that programmers’ vibe. Here, we will install fish shell on Ubuntu and other Ubuntu-based Linux distros.",
    title1: "Step 1: Adding fish official repository.",
    title2: "Step 2: Updating the repository lists.",
    title3: "Step 3: Installing fish shell.",
    code1: "sudo apt-add-repository ppa:fish-shell/release-3",
    code2: "sudo apt update",
    code3: "sudo apt install fish --yes",
  },
};
