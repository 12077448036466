import React from "react";
import { motion } from "framer-motion";

// Importing styles and components
import "./contactpage.styles.scss";
import { LineHeading } from "../../components/headings/headings.components.jsx";
import { ContactForm } from "../../components/forms/contact-form/contact-form.components.jsx";
import ScrollTop from "../../components/ScrollTop.js";
import { UnderlineLink } from "../../components/Utility.js";

// Animation
import {
  pageAnimation,
  sliderContainer,
  slider,
  fade,
} from "../../animation.js";

const Contactpage = () => {
  return (
    <motion.div
      className="contactpage"
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <motion.div className="frame frame1" variants={slider}></motion.div>
        <motion.div className="frame frame2" variants={slider}></motion.div>
        <motion.div className="frame frame3" variants={slider}></motion.div>
        <motion.div className="frame frame4" variants={slider}></motion.div>
      </motion.div>
      <header className="contactpage__header">
        <motion.h2 className="contact-mail" variants={fade}>
          Say Hi! to Shamshad &mdash; <br />{" "}
          <UnderlineLink
            to={{
              pathname:
                "mailto:as.shamshadansari?Subject=Hello%20User",
            }}
            target="_blank"
            rel="noreferrer"
          >
            as.shamshadansari@gmail.com
          </UnderlineLink>
        </motion.h2>
      </header>
      <LineHeading TextContent="Contact Form" />
      <ContactForm variants={fade} />
      <ScrollTop />
    </motion.div>
  );
};

export default Contactpage;
