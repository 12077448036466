import React from "react";
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";

// Importing Styles
import "./secondary-card.styles.scss";

function SecondaryCard({ externalPath, gitPath, title, desc, techList }) {
  return (
    <Link
      className="project"
      to={{ pathname: `${externalPath}` }}
      target="_blank"
      rel="noreferrer"
    >
      <div className="project-container">
        <header>
          <div className="project-top">
            <div className="project-folder">
              <FeatherIcon icon="folder" />
            </div>
            <div className="project-links">
              {gitPath ? (
                <>
                  <Link to={{ pathname: `${gitPath}` }} target="_blank" rel="noreferrer">
                    <FeatherIcon icon="github" />
                  </Link>
                  <Link to={{ pathname: `${externalPath}` }} target="_blank" rel="noreferrer">
                    <FeatherIcon icon="external-link" />
                  </Link>
                </>
              ) : (
                <Link to={{ pathname: `${externalPath}` }} target="_blank" rel="noreferrer">
                  <FeatherIcon icon="external-link" />
                </Link>
              )}
            </div>
          </div>
          <h3 className="project-title">{title}</h3>
          <p className="project-description">{desc}</p>
        </header>
        <footer>
          <ul className="project-tech-list">
            {techList.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </footer>
      </div>
    </Link>
  );
}

export default SecondaryCard;
